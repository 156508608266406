import React  from 'react'
import Navbar from "./components/Navbar";
import Work from "./Pages/Work";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import NotFound from "./Pages/NotFound";
function App() {
  return (
    <>
      <Router>
        <Navbar />
        <section className="mt-[25px] md:mt-[69px] max-w-[1512px] mx-auto px-[25px] md:px-[80px] select-none ">
          <Routes>
            <Route path="/" element={<Work />} />
            <Route path="/work" element={<Work />} />
            <Route path="/about" element={<About />} />
            {/* <Route path="/contact" element={<Contact />} /> */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </section>
        <Footer />
      </Router>
    </>
  );
}

export default App;
