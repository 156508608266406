import React from "react";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="fixed top-0 left-0 z-50 h-screen w-screen bg-[#EBEDED] flex items-center justify-center flex-col gap-5">
      <h1 className="text-[#59C4CA] text-4xl" >Page Not Found</h1>
      <Link to="/" className=" bg-[#59C4CA] text-[#F5F5F5] font-[Poppins] text-[18px] leading-[21px] rounded-[10px] p-4 " >Go to Home Page</Link>
    </div>
  );
};

export default NotFound;
