import React from "react";
import { Link } from "react-router-dom";

const ProjectCard = ({
  thumbnail,
  title,
  description,
  type,
  link,
  styles,
  caseStudyLink,
  btn
}) => {
  return (
    <>
      <div
        className={
          `card flex flex-col min-w-[180px] mx-auto md:max-h-[250px lg:max-h-full text-${styles.textAlign} ` +
          (styles.textAlign === "left" ? "md:flex-row" : "md:flex-row-reverse")
        }
        style={{ backgroundColor: `${styles.backgroundColor}` }}
      >
        <div className="thumbnail">
          <img
            className="h-[180px] md:h-[250px] lg:h-[425px] object-cover "
            src={thumbnail}
            alt={title}
          />
        </div>

        <div
          className={`cardContent px-[25px] lg:px-[40px] pt-[21px] pb-[25px] lg:pt-[80px] lg:pb-[80px] md:py-[51px] md:px-[15px] md:min-w-[230px] lg:min-w-[500px] `}
        >
          <div
            className="projectTitle font-['Encode_Sans_SC'] text-[16px] leading-[30px] text-[#262121] md:text-[18px] md:leading-[40px] lg:text-[36px] lg:leading-[65px] opacity-90 font-medium
        "
          >
            {title}
          </div>
          <div
            className="projectCategories text-[#262121] text-[14px] leading-[21px]  font-[Poppins] lg:text-[24px] lg:leading-[36px] opacity-90 font-normal mb-[9px] mt-[8px] lg:mb-[20px] lg:mt-[5px] md:text-[14px] md:leading-[21px] md:mb-[10px] md:mt-[3px]
         "
          >
            {type}
          </div>
          <div
            className={
              styles.textAlign === "right"
                ? `flex justify-end`
                : "flex justify-start"
            }
          >
            <div
              className={`projectDescription text-[#262121] text-[10px] leading-[14px] font-[Poppins] max-w-[186px] lg:max-w-[335px] opacity-75 md:text-[10px] md:leading-[15px] lg:text-[18px] lg:leading-[27px]
        `}
            >
              {description}
            </div>
          </div>
          <div
            className={`caseStudy cursor-pointer mt-[25px] lg:mt-[50px] text-[14px] font-extrabold leading-[17px] lg:text-[25px] lg:leading-[30px] font-[Inter] md:text-[15px] md:leading-[18px] md:mt-[25px] 
        `}
            style={{ color: `${styles.color}` }}
          >
            <a href={link} target="_blank" rel="noopener noreferrer">
              {" "}
              {btn || "CASE STUDY >"}{" "}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectCard;
