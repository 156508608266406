import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    setToggle(false);
  }, [pathname]);

  const toggleMenu = () => {
    if (toggle === true) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  };

  return (
    <nav className=" sticky top-0 z-[9999] bg-[#EBEDED] md:static  ">
      <div className="flex justify-between items-center w-full p-[25px] md:py-[46px] md:px-[80px] duration-300 ">
        <div className="logo">
          <Link to="/">
            <img
              className="w-[50px] md:w-[80px] rounded-full opacity-70"
              src="/assets/ayque_logo.jpeg"
              alt="AQ"
            />
          </Link>
        </div>
        <div className="menu font-[Inter] ">
          <ul
            className={
              " top-[80px] left-0 overflow-y-hidden absolute w-full text-center duration-500 bg-[#EBEDED] shadow-[0px_1px_2px_rgba(0,0,0,0.15)] ease-in-out  max-h-0 md:top-auto md:max-h-[180px] md:static md:flex md:gap-[55px] md:shadow-none md:py-[25px] md:px-[10px] " +
              (toggle ? "max-h-full py-[5px]" : "")
            }
          >
            <Link to="/">
              <li
                className={
                  "  leading-[12.1px]  md:leading-[21.78px] hover:opacity-100 font-semibold hover:text-[#59C4CA] hover: text-[#737373] hover:bg-[#EBEDED] transition-all duration-200 cursor-pointer py-[10px] hover:bg-none hover:font-extrabold " +
                  (pathname === "/"
                    ? "text-[#59C4CA] opacity-100 text-[14px] md:text-[22px]"
                    : "text-[10px] md:text-[18px] opacity-75")
                }
              >
                WORK
              </li>
            </Link>
            <Link to="/about">
              <li
                className={
                  "  leading-[12.1px]  md:leading-[21.78px] hover:opacity-100 font-semibold hover:text-[#59C4CA] hover: text-[#737373] hover:bg-[#EBEDED] transition-all duration-200 cursor-pointer py-[10px] hover:bg-none hover:font-extrabold " +
                  (pathname === "/about"
                    ? "text-[#59C4CA] opacity-100 text-[14px] md:text-[22px]"
                    : "text-[10px] md:text-[18px] opacity-75")
                }
              >
                ABOUT
              </li>
            </Link>
            {/* <Link to="/contact">
              <li
                className={
                  "  leading-[12.1px]  md:leading-[21.78px] hover:opacity-100 font-semibold hover:text-[#59C4CA] hover: text-[#737373] hover:bg-[#EBEDED] transition-all duration-200 cursor-pointer py-[10px] hover:bg-none hover:font-extrabold " +
                  (pathname === "/contact"
                    ? "text-[#59C4CA] opacity-100 text-[14px] md:text-[22px]"
                    : "text-[10px] md:text-[18px] opacity-75")
                }
              >
                CONTACT
              </li>
            </Link> */}
          </ul>
        </div>
        <div className="menu-btn cursor-pointer md:hidden" onClick={toggleMenu}>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.9">
              <path
                d="M22 20.4286C22 20.8453 21.8551 21.245 21.5973 21.5397C21.3394 21.8344 20.9897 22 20.625 22L1.375 22C1.01033 22 0.660589 21.8344 0.402727 21.5397C0.144864 21.245 -5.96046e-07 20.8453 -5.96046e-07 20.4286C-5.96046e-07 20.0118 0.144864 19.6121 0.402727 19.3174C0.660589 19.0227 1.01033 18.8571 1.375 18.8571L20.625 18.8571C20.9897 18.8571 21.3394 19.0227 21.5973 19.3174C21.8551 19.6121 22 20.0118 22 20.4286Z"
                fill="#262121"
              />
              <path
                d="M22 1.57139C22 1.98816 21.8551 2.38786 21.5973 2.68256C21.3394 2.97726 20.9897 3.14282 20.625 3.14282L1.375 3.14282C1.01033 3.14282 0.660589 2.97726 0.402727 2.68256C0.144864 2.38786 -5.96046e-07 1.98816 -5.96046e-07 1.57139C-5.96046e-07 1.15462 0.144864 0.754924 0.402727 0.460224C0.660589 0.165524 1.01033 -3.58139e-05 1.375 -3.58139e-05L20.625 -3.58139e-05C20.9897 -3.58139e-05 21.3394 0.165524 21.5973 0.460224C21.8551 0.754924 22 1.15462 22 1.57139Z"
                fill="#262121"
              />
              <path
                d="M20.625 12.5715C20.9897 12.5715 21.3394 12.406 21.5973 12.1113C21.8551 11.8166 22 11.4169 22 11.0001C22 10.5833 21.8551 10.1836 21.5973 9.88894C21.3394 9.59424 20.9897 9.42868 20.625 9.42868L9.625 9.42868C9.26033 9.42868 8.91059 9.59424 8.65273 9.88894C8.39487 10.1836 8.25 10.5833 8.25 11.0001C8.25 11.4169 8.39487 11.8166 8.65273 12.1113C8.91059 12.406 9.26033 12.5715 9.625 12.5715L20.625 12.5715Z"
                fill="#262121"
              />
            </g>
          </svg>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
