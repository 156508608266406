import React from "react";
import { useState, useEffect } from "react";
import ProjectCard from "../components/ProjectCard";

const Work = () => {
  // const [projects, setProjects] = useState([]);
  let projects = [{
    _id:1,
    project_thumbnail_link : '/assets/thumbnail/smart_watch.jpg',
    project_title : 'Doggy App',
    project_description : 'Train your doggy very simply through this app. ',
    project_type : 'UI/UX Design',
    project_case_study_link : 'https://www.behance.net/gallery/151519921/Doggy-Appcase-study',
    project_styles: {
      backgroundColor : '#D9DBDB',
      textAlign : 'left',
      color : '#080707'
    }
  },{
    _id:2,
    project_thumbnail_link : '/assets/thumbnail/clip_magic.png',
    project_title : 'CLIP MAGIC WEBSITE',
    project_description : 'Extract shorts from your favourite videos.',
    project_type : 'UX/UI Design',
    project_case_study_link : 'https://www.figma.com/design/gVH6Z849sgj6dBnW7eKGpu/Clip-Magic?node-id=29-3&node-type=canvas&t=J8v6EcZHEokHv1wl-0',
    project_btn:'UI DESIGN >',
    project_styles: {
      backgroundColor : '#4800A514',
      textAlign : 'right',
      color : '#4800A5'}
    },{
    _id:3,
    project_thumbnail_link : '/assets/thumbnail/home_suvidha.png',
    project_title : 'Home Suvidha App',
    project_description : 'Clean your home essential things with our services.',
    project_type : 'UX/UI Design',
    project_case_study_link : 'https://www.figma.com/design/h10dp6xmouyKgRr9hQVyED/Home-Suvidha-App?node-id=0-1&node-type=canvas&t=D1fXKJU9eD6Dgjze-0',
    project_btn:'UI DESIGN >',
    project_styles: {
      backgroundColor : '#EB575714',
      textAlign : 'left',
      color : '#EB5757'}
    },{
    _id:4,
    project_thumbnail_link : '/assets/thumbnail/morning_fresh.jpg',
    project_title : 'Morning Fresh App',
    project_description : 'Deliver the morning breakfast stuff to people’s home.',
    project_type : 'UX/UI Design',
    project_case_study_link : 'https://www.behance.net/gallery/151517791/Morning-Fresh-Appcase-study',
    project_styles: {
      backgroundColor : '#DBE8EB',
      textAlign : 'right',
      color : '#21B4D9'
    }
  }]

  // const getProjects = () => {
  //   fetch(process.env.REACT_APP_API_DOMAIN + "/api/project")
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setProjects(data);
  //       // console.log(data)
  //     });
  // };

  // useEffect(() => {
  //   getProjects();
  // }, []);

  return (
    <section>
      {/* headings */}
      <div>
        <h1
          className=" 
        font-['Encode_Sans_SC'] tracking-[-0.005em] font-medium text-[#262121] text-center leading-[30px] text-[18px] mx-auto
         md:text-[44px] md:leading-[65px] max-w-[350px] md:max-w-[1050px] "
        >
          Hi,
          <br />
          I’m &nbsp;Ayan, a ui/ux and product designer living and adventuring in
          Mumbai.
        </h1>
      </div>

      <section>
        {/* PROJECTS label */}
        <div
          className="my-[60px] flex flex-col justify-center items-center text-[#262121] text-[10px] leading-[15px] font-[Poppins] opacity-75 gap-[2px] md:text-[18px] md:leading-[27px] md:mt-[120px] md:mb-[100px]
      "
        >
          <h3>PROJECTS</h3>
          <svg
            className="w-[8px] md:w-[16px] overflow-visible "
            viewBox="0 0 8 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.75"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 0.9868L4.05036 5L8 1.0864L7.00436 0.0995998L4.05036 3.027L0.995635 0L0 0.9868Z"
              fill="#262121"
            />
            <path
              className="svg-animation"
              opacity="0.75"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 4.57888L4.05036 11L8 4.73824L7.00436 3.15936L4.05036 7.8432L0.995635 3L0 4.57888Z"
              fill="#262121"
            />
          </svg>
        </div>
      </section>

      {/* PROJECTS card */}
      <section>
        <div className="cardContainer flex flex-col gap-[60px] max-w-[400px] md:max-w-fit mx-auto md:gap-[120px] ">
          {projects.map((element) => {
            
              return (
                <ProjectCard
                key={element._id}
                thumbnail={element.project_thumbnail_link}
                title={element.project_title}
                description={element.project_description}
                type={element.project_type}
                link={element.project_case_study_link}
                styles={element.project_styles}
                btn={element.project_btn}
                />
                );
              
          })}
        </div>
      </section>

      <section>
        {/* upcoming project  PROJECTS label */}
        {/* <div
          className="my-[60px] flex flex-col justify-center items-center text-[#262121] text-[10px] leading-[15px] font-[Poppins] opacity-75 gap-[2px] md:text-[18px] md:leading-[27px] md:mt-[120px] md:mb-[100px]
      "
        >
          <h3>COMING SOON</h3>
          <svg
            className="w-[8px] md:w-[16px] overflow-visible "
            viewBox="0 0 8 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.75"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 0.9868L4.05036 5L8 1.0864L7.00436 0.0995998L4.05036 3.027L0.995635 0L0 0.9868Z"
              fill="#262121"
            />
            <path
              className="svg-animation"
              opacity="0.75"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 4.57888L4.05036 11L8 4.73824L7.00436 3.15936L4.05036 7.8432L0.995635 3L0 4.57888Z"
              fill="#262121"
            />
          </svg>
        </div> */}
        </section>

        {/* <section>
        <div className="cardContainer flex flex-col gap-[60px] max-w-[400px] md:max-w-fit mx-auto md:gap-[120px] ">
        {projects.map((element) => {
            if(element.project_status === 'comingsoon'){
              return (
                <ProjectCard
                key={element._id}
                thumbnail={element.project_thumbnail_link}
                title={element.project_title}
                description={element.project_description}
                type={element.project_type}
                link={element.project_case_study_link}
                styles={element.project_styles}
                caseStudyLink="COMING SOON"
                />
                );
              }
          })}
          </div>
      </section> */}
    </section>
  );
};

export default Work;
