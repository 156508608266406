import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <section className="">
      <div className="heading mb-[20px] md:mb-[30px] ">
        <h3 className="font-['Encode_Sans_SC'] font-medium text-[9px] leading-[27px] md:text-[22px] md:leading-[60px] text-[#737373]">
          Hello There !
        </h3>
        <h1 className="font-['Encode_Sans_SC'] font-medium text-[18px] leading-[27px] text-[#262121] md:text-[44px] md:leading-[60px] ">
          I’m &nbsp;Ayan qureshi,
        </h1>
      </div>

      <div>
        <div className="md:flex flex-row-reverse gap-[50px] xl:gap-[100px] items-center mb-[60px] md:mb-[120px] ">
          <div className="img mb-[25px] md:mb-[25px] self-start ">
            <img src="/assets/ayan_qureshi.jpg" alt="Ayan Qureshi" />
          </div>

          <div className=" lg:max-w-[560px] self-start ">
            <p className=" w-full font-[Poppins] text-[14px] leading-[21px] text-[#262121] md:text-[24px] md:leading-[36px] ">
              Hi, I am Ayan. I am a UI/UX designer with 1 years of experiences. I bring a unique blend of creativity and analytical thinking to the table. With a user-centric approach, I've consistently crafted designs that not only look visually appealing but also enhance the overall user experience. My proficiency in leveraging industry-standard tools, coupled with a solid foundation in user research, allows me to translate complex requirements into intuitive and effective design solutions.
            </p>
          </div>
        </div>

        <div className="">
          <h2 className=" font-['Encode_Sans_SC'] font-medium text-[21px] leading-[30px] text-[#262121] mb-[25px] md:mb-[30px] md:text-[44px] md:leading-[65px]  ">
            Goals & Ambitions
          </h2>
          <p className=" w-full font-[Poppins] text-[14px] leading-[21px] text-[#262121] mb-[100px] md:mb-[200px] md:text-[24px] md:leading-[36px] ">
            To me, a digital interface is the opportunity to create useful and
            inclusive designs, improving people’s daily experiences.
            Understanding the user, communicating with them during research, and
            putting myself in the user’s place, so that I can understand their
            problems. Design the product keeps in mind the important elements of
            the brand while designing. My goal as a designer is to fuse
            creativity with analytical thinking to deliver intuitive solutions
            for all users.
          </p>
        </div>

        <div className="links flex flex-wrap gap-[10px] md:justify-between justify-center items-center md:max-w-[1166px] mx-auto ">
          <a href="/assets/resume.pdf" target="_blank">
            <div className=" mx-auto text-center font-[Inter] text-[12px] leading-[15px] font-extrabold text-[#F5F5F5] rounded-[10px] md:rounded-[15px] bg-[#59C4CA] py-[12px] shadow-[0px_4px_2px_rgba(89,196,202,0.3)] md:shadow-[0px_8px_4px_rgba(89,196,202,0.3)] md:w-[420px] min-w-[240px] md:text-[22px] md:leading-[27px] md:py-[26px] md:h-[80px] hover:shadow-none hover:scale-[0.97] focus:shadow-none focus:scale-[0.97] ">
              View my resume
            </div>
          </a>
          <Link to="mailto:ayanque121@gmail.com">
            <div className=" mx-auto text-center font-[Inter] text-[12px] leading-[15px] font-extrabold text-[#F5F5F5] rounded-[10px] md:rounded-[15px] bg-[#59C4CA] py-[12px] shadow-[0px_4px_2px_rgba(89,196,202,0.3)] md:shadow-[0px_8px_4px_rgba(89,196,202,0.3)] md:w-[420px] min-w-[240px] md:text-[22px] md:leading-[27px] md:py-[26px] md:h-[80px] hover:shadow-none hover:scale-[0.97] focus:shadow-none focus:scale-[0.97] ">
              Contact me
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default About;
